import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../MyNavbar.css";

const MyNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="page_toplogo table_section table_section_md ls section_padding_top_25 section_padding_bottom_25">
        <div className="container">
          <div className="row">
            <div className="col-md-3 text-center text-md-left">
              {" "}
              <a href="\" className="logo top_logo">
                <img src="images/logo.jpg" alt=""></img>
              </a>{" "}
            </div>
            <div className="col-md-9 text-center text-md-right">
              <div className="inline-teasers-wrap">
                <div className="teaser small-teaser media">
                  <div className="media-left media-middle">
                    <div className="teaser_icon size_small border_icon highlight2 rounded">
                      {" "}
                      <i className="rt-icon2-pen"></i>{" "}
                    </div>
                  </div>
                  <div className="media-body media-middle">
                    <h4>0 (800) 123 4567</h4>
                    <p className="greylinks fontsize_16 highlight2 ">
                      {" "}
                      info@novavision.in
                    </p>
                  </div>
                </div>
                <div className="teaser small-teaser media">
                  <div className="media-left media-middle">
                    <div className="teaser_icon size_small border_icon highlight2 rounded">
                      {" "}
                      <i className="rt-icon2-map-pin"></i>{" "}
                    </div>
                  </div>
                  <div className="media-body media-middle">
                    <h4> Plot No. 68, Khasra No. 1680</h4>
                    <p className="greylinks fontsize_12">
                      Hanumant Enclave, Salempur, Bahadrabad, Haridwar 249402
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className="my-navbar">
        <div className="navbar-container">
          <div className="navbar-toggle" onClick={toggleNavbar}>
            ☰
          </div>
          <div className={`sidebar ${isOpen ? "active" : ""}`}>
            <div className="close-btn" onClick={toggleNavbar}>
              &times;
            </div>
            <Link to="/" className="nav-link" onClick={() => setIsOpen(false)}>
              Home
            </Link>
            <Link
              to="/about"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              About Us
            </Link>
            <Link
              to="/products"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Products
            </Link>
            <Link
              to="/COA"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Product COA
            </Link>
            <Link
              to="/contact"
              className="nav-link"
              onClick={() => setIsOpen(false)}
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default MyNavbar;
