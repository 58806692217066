import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../ProductSlider.css";
import { useNavigate } from "react-router-dom";

const ProductSlider = () => {
  const navigate = useNavigate();
  const mainSlider = useRef(null);
  const navSlider = useRef(null);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [loopCount, setLoopCount] = useState(0);

  const maxLoops = 5;
  const slideCount = 10; // Number of slides in the main slider

  const handleClick = () => {
    navigate("/products");
  };

  useEffect(() => {
    setNav1(mainSlider.current);
    setNav2(navSlider.current);
  }, []);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    asNavFor: nav2,
    infinite: true,
    speed: 500,
    cssEase: "linear",
    afterChange: (currentSlide) => {
      if (currentSlide === slideCount - 1) {
        setLoopCount((prevCount) => prevCount + 1);
      }
    },
    beforeChange: (current, next) => {
      if (loopCount >= maxLoops) {
        mainSlider.current.slickPause();
      }
    },
  };

  const settingsNav = {
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    arrows: false,
    asNavFor: nav1,
    infinite: true,
    speed: 500,
    cssEase: "linear",
  };

  return (
    <section
      id="products"
      className="ds color parallax page_gallery section_padding_top_100 section_padding_bottom_150 columns_padding_25"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2 className="section_header">Our Products</h2>
            <p className="highlight bottommargin_20">
              <strong>
                We offer a wide range of allopathic and Ayurvedic products
                tailored for various health needs. Our selection includes pain
                relief solutions, diabetes management, liver care,
                dermatological treatments, and hair care products. Each product
                is crafted with care to ensure the highest quality and efficacy,
                providing holistic solutions for better health and well-being.
                Whether you're seeking traditional remedies or modern medical
                treatments, our comprehensive product line is designed to
                support your health journey effectively. Explore our offerings
                to find the perfect solution for your healthcare needs.
              </strong>
            </p>
            <p className="topmargin_30"></p>

            <div className="nav-slider-container">
              <Slider {...settingsNav} ref={navSlider}>
                <div>
                  <img
                    src="images/Products/Pridesic-Sp-Thumb.jpg"
                    alt="Thumbnail 1"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Priderub-DSR-Thumb.jpg"
                    alt="Thumbnail 2"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Pridemont-M-Thumb.jpg"
                    alt="Thumbnail 3"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Pridelactin-Thumb.jpg"
                    alt="Thumbnail 4"
                  />
                </div>
                <div>
                  <img src="images/Products/NVK-Thumb.jpg" alt="Thumbnail 5" />
                </div>
                <div>
                  <img
                    src="images/Products/A-Orava-Thumb.jpg"
                    alt="Thumbnail 6"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-OnionHairOil-Thumb.jpg"
                    alt="Thumbnail 7"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-N-HepaNova-Thumb.jpg"
                    alt="Thumbnail 8"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-DIABISAFE-Thumb.jpg"
                    alt="Thumbnail 9"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/A-Anshderma-Thumb.jpg"
                    alt="Thumbnail 10"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/Zympride-Thumb.jpg"
                    alt="Thumbnail 10"
                  />
                </div>
                <div>
                  <img
                    src="images/Products/NvStrum-Thumb.jpg"
                    alt="Thumbnail 11"
                  />
                </div>
              </Slider>
            </div>

            <p className="topmargin_30">
              <button
                className="theme_button color2 inverse"
                onClick={handleClick}
              >
                See All Products
              </button>
            </p>
          </div>
          <div className="col-md-6">
            <div className="main-slider-container">
              <Slider {...settingsMain} ref={mainSlider}>
                <div>
                  <img src="images/Products/Pridesic-Sp.jpg" alt="Product 1" />
                </div>
                <div>
                  <img src="images/Products/Priderub-DSR.jpg" alt="Product 2" />
                </div>
                <div>
                  <img src="images/Products/Pridemont-M.jpg" alt="Product 3" />
                </div>
                <div>
                  <img src="images/Products/Pridelactin.jpg" alt="Product 4" />
                </div>
                <div>
                  <img src="images/Products/NVK.jpg" alt="Product 5" />
                </div>
                <div>
                  <img src="images/Products/A-Orava.jpg" alt="Product 6" />
                </div>
                <div>
                  <img
                    src="images/Products/A-OnionHairOil.jpg"
                    alt="Product 7"
                  />
                </div>
                <div>
                  <img src="images/Products/A-N-HepaNova.jpg" alt="Product 8" />
                </div>
                <div>
                  <img src="images/Products/A-DIABISAFE.jpg" alt="Product 9" />
                </div>
                <div>
                  <img src="images/Products/A-Anshderma.jpg" alt="Product 10" />
                </div>
                <div>
                  <img src="images/Products/Zympride.jpg" alt="Product 10" />
                </div>
                <div>
                  <img src="images/Products/NvStrum.jpg" alt="Product 11" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductSlider;
